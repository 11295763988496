import { createContext, useContext } from "react"
import type { FC, PropsWithChildren } from "react"
import { useSelectCards } from "./Sections/SelectCards/useSelectCards"

type OrderCardContextProps = Omit<ReturnType<typeof useSelectCards>, "model"> & {
  model: ReturnType<typeof useSelectCards>["model"] | null
}

const OrderCardContext = createContext<OrderCardContextProps>({
  currency: null,
  filteredCryptos: [],
  flatPricesOptions: [],
  isLoadingRates: false,
  isSelecting: false,
  page: 1,
  model: null,
  rates: [],
  isConfirming: false,
  cardTypes: [],
  activeStep: 1,
  setActiveStep: () => {},
  onRefreshInformation: () => Promise.resolve(),
  searchValue: "",
  brandInfo: null,
  isWidget: false,
  setPage: () => {},
  confirmResult: {
    token: "",
    timestamp: "",
    transaction_id: 0,
    ex_transaction_id: 0,
    fiat_amount: 0,
    fiat_currency: "",
    crypto_amount: 0,
    crypto_currency: "",
    amount_withdrawal: "",
    wallet: "",
  },
  onConfirm: () => {},
  handleNavigate: () => {},
  onResetTransaction: () => {},
  setPaymentMethod: () => {},
  onFullReset: () => {},
  isTransactionSuccess: false,
  verifyResponse: null,
  setSearchValue: () => {},
  onRefresh: () => {},
  onVerify: () => Promise.resolve(),
  onSelect: () => {},
})

export const OrderCardProvider: FC<PropsWithChildren<OrderCardContextProps>> = ({ children, ...props }) => (
  <OrderCardContext.Provider value={props}>{children}</OrderCardContext.Provider>
)

export const useOrderCard = () => useContext<OrderCardContextProps>(OrderCardContext)
